<template>
  <div :class="['idex-wrap', 'desktop', ClassName, LocaleClass]">
    <button id="sound_btn" class="ignore ignore2" @click="sound_btn"></button>
    <pageHead device="desktop" page="layouts"/>
    <autoRefresh />
    <div class="btn-flexall">

      <userModBtn2 :value="floatBtn" />

      <!-- <btnFlexService /> -->
    </div>
    <Header></Header>
    <main>
      <slot name="content">
        <div class="indexMain">
          <Falling />
          <pageHead device="desktop" page="index"/>
          <idxSlider />
          <idxMarquee04 />

          <div class="idx_fast_area">
            <h2 class="title"></h2>
            <ul>
              <li>
                <a href="/game/sport">
                  <b-img :src="require(`@/website/${DEF_webStyle}/images/pc/btn_sport.webp`)" alt=""></b-img>
                </a>
              </li>
              <li>
                <a href="/game/live">
                  <b-img :src="require(`@/website/${DEF_webStyle}/images/pc/btn_casino.webp`)" alt=""></b-img>
                </a>
              </li>
              <li>
                <a href="/game/egame">
                  <b-img :src="require(`@/website/${DEF_webStyle}/images/pc/btn_slot.webp`)" alt=""></b-img>
                </a>
              </li>
              <li>
                <a href="/game/chess">
                  <b-img :src="require(`@/website/${DEF_webStyle}/images/pc/btn_poker.webp`)" alt=""></b-img>
                </a>
              </li>
              <li>
                <a href="/game/fish">
                  <b-img :src="require(`@/website/${DEF_webStyle}/images/pc/btn_fish.webp`)" alt=""></b-img>
                </a>
              </li>
            </ul>
          </div>

          <idxInfo />
          <div class="idxFooter footerService">
            <div class="footer-group footer__01">
              <h2 class="title"></h2>
              <div class="listBox">
                <ul class="footer-list">
                  <li v-for="(item, index) in list" :key="index">
                    <i :class="item.class"></i>
                    <span>
                      <b>{{ item.title }}</b>
                      <q>{{ item.subtxt }}</q>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="idxFooter footerCorp">
            <div class="footer-group footer__02">
              <div class="footer-list gray">
                <div class="left">
                  <h4>{{ $t("store.partner") }}</h4>
                  <div class="footer-product" v-for="(image, index) in FooterProductLogo" :key="index">
                    <template v-if="[42, 52].indexOf(image.id) >= 0">
                      <img :src="require(`@/website/${DEF_webStyle}/images/game/logo/product_${image.id}.webp`)" :alt="image.name"/>
                    </template>
                    <template v-else>
                      <img :src="require(`@/assets/all/images/logo/product_${image.id}.webp`)" :alt="image.name"/>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <idxDownload />
        </div>

      </slot>
      <div class="decHeaderTopL"></div>
      <div class="decHeaderTopR"></div>
      <div class="decBg"></div>
      <div class="decTop"></div>
      <div class="decLeft"></div>
      <div class="decRight"></div>
      <div class="decRight2"></div>
      <div class="decBottom"></div>
    </main>


    <div class="idxFooter">
      <div class="footer-gotop else" @click="scrollGoTop()">
        <i class="icon icon-arrowtop"></i>
      </div>
      <div class="footer-group footer__03">
        <div class="footer-link">
          <ul>
            <li v-for="(link, index) in idxFooterList" :key="index">
              <a v-if="link.blank" target="_blank" :href="link.url">{{ $t(link.name) }}</a>
              <nuxt-link v-else :to="link.url">{{ $t(link.name) }}</nuxt-link>
            </li>
          </ul>
          <p>{{ $t("store.footer_declare") }}</p>
          <p>{{ Copyright }}</p>
        </div>
      </div>
    </div>

    <dialogMarquee />
    <dialogMaintain />
    <dialogExchPoint v-if="ShowTransferPoint" />
    <dialogTransferPoint v-if="ShowExchPoint" />
    <overlay v-model="IsLoading"></overlay>
    <upAgentCode />

    <audio id="beep" src="/audio/btnClick.mp3" type="audio/mp3" muted></audio>
    <slot name="other" />
  </div>
</template>
<script>
  const Header = require(`~/components/desktop/${process.env.pc_header}.vue`).default;
  const idxGame = require(`~/components/desktop/${process.env.pc_game}.vue`).default;
  const idxInfo = require(`~/components/desktop/${process.env.pc_info}.vue`).default;
  const idxDownload = require(`~/components/desktop/${process.env.pc_idxDownload}.vue`).default;
  const Falling = require(`~/components/desktop/${process.env.pc_falling}.vue`).default;
  //const Footer = require(`~/components/desktop/${process.env.pc_footer}.vue`).default;
  var idxFooter = [];
  try { idxFooter = require("$json/idxFooter.json"); } catch (e) {}
  let floatBtn = [];
  try { floatBtn = require("$json/floatBtn.json"); } catch (e) {}
  import pageHead from "~/components/common/pageHead.vue";
  import autoRefresh from "~/components/common/autoRefresh.vue";
  import userModBtn from "~/components/desktop/userModBtn.vue";
  import userModBtn2 from "~/components/desktop/userModBtn2.vue";
  import btnFlexService from "~/components/desktop/btnFlexService.vue";
  import idxSlider from "~/components/desktop/idxSlider2.vue";
  import dialogMarquee from "~/components/desktop/dialogMarquee.vue";
  import dialogMaintain from "~/components/desktop/dialogMaintain.vue";
  import dialogExchPoint from "~/components/desktop/dialogExchPoint.vue";
  import dialogTransferPoint from "~/components/desktop/dialogTransferPoint.vue";
  import idxMarquee04 from "~/components/desktop/idxMarquee.vue";
  import overlay from "@/components/common/overlay.vue";
  import upAgentCode from "@/components/common/upAgentCode.vue";
  import { mapState, mapActions, mapGetters } from "vuex";
  export default {
    components: {
      pageHead,
      autoRefresh,
      btnFlexService,
      Header,
      userModBtn,
      userModBtn2,
      idxSlider,
      idxGame,
      idxInfo,
      idxDownload,
      dialogMaintain,
      dialogMarquee,
      dialogExchPoint,
      dialogTransferPoint,
      overlay,
      upAgentCode,
      idxMarquee04,
      Falling,
    },
    data(){
      return{
        floatBtn,
        list: this.handleList(),
        idxFooter,
        LocaleClass: 'lang-'
      }
    },
    created() {
      if (this.floatBtn == null  || this.floatBtn.length == 0)
        this.floatBtn = getJsonFile(process.env.DEF_webStyle, 'floatBtn');
    },
    mounted() {
      this.changeLang();

      this.$nextTick(function () {
        const sound_btn = document.getElementById('sound_btn');
        if (sound_btn) {
          sound_btn.click();
        }

        this.LocaleClass = 'lang-' + this.$i18n.locale;
      });
    },
    methods: {
      sound_btn(){
        if(this.DEF_webStyle === 'kb88'){
          const btnLinks = document.querySelectorAll("button, a, .tab-group, .subm_group, .desktop-mar, ul.navbar, select, input, .close_selfbox-s, .overflow-auto, .custom-checkbox, .sound_on");
          const audio = document.querySelector("#beep");
          const context = new AudioContext();
          function isIOS() {
            return /iPhone|iPad|iPod/i.test(navigator.userAgent);
          };
          btnLinks.forEach((btnLink) => {
            if (btnLink.classList.contains("ignore")) {
              return;
            };
            btnLink.removeEventListener("click", () => {
              if(this.IsAudio == true){
                if (context.state === 'suspended') {
                  context.resume().then(() => {});
                };
                if (isIOS()) {
                  setTimeout(function() {
                    audio.muted = false;
                    audio.play();
                  }, 200);
                } else {
                  audio.muted = false;
                  audio.play();
                };
              };
            });
            btnLink.addEventListener("click", () => {
              if(this.IsAudio == true){
                if (context.state === 'suspended') {
                  context.resume().then(() => {});
                };
                if (isIOS()) {
                  setTimeout(function() {
                    audio.muted = false;
                    audio.play();
                  }, 200);
                } else {
                  audio.muted = false;
                  audio.play();
                };
              };
            });
          });
        };
      },
      scrollGoTop(){
        window.scrollTo({ top: 0 });
      },
      handleList() {
        return [
          {
            class: "icon icon-deposit",
            title: this.$t("store.footer01_title"),
            subtxt: this.$t("store.footer01_sub"),
          },
          {
            class: "icon icon-point",
            title: this.$t("store.footer02_title"),
            subtxt: this.$t("store.footer02_sub"),
          },
          {
            class: "icon icon-fast",
            title: this.$t("store.footer03_title"),
            subtxt: this.$t("store.footer03_sub"),
          },
          {
            class: "icon icon-diamond",
            title: this.$t("store.footer04_title"),
            subtxt: this.$t("store.footer04_sub"),
          },
          {
            class: "icon icon-evaluation",
            title: this.$t("store.footer05_title"),
            subtxt: this.$t("store.footer05_sub"),
          },
        ];
      },
      changeLang() {
        this.list = this.handleList();
      },
    },
    watch: {
      "$i18n.locale"(language) {
        this.changeLang();
        this.LocaleClass = 'lang-' + this.$i18n.locale;
      },
      "$route.path": function(path) {
        if(path !== '/'){
          this.scrollGoTop();
        }
      }
    },
    computed: {
      ShowTransferPoint() {
        return this.$auth.$state.loggedIn === true;
      },
      ShowExchPoint() {
        return this.$auth.$state.loggedIn === true && this.WebSetting.SHOW_CONVERSION === "1";
      },
      ClassName() {
        let ModalClass = { 'idex-wrap': true, 'desktop': true };
        if (!this.FestivalClass)
          return ModalClass;

        return Object.assign(ModalClass, this.FestivalClass);
      },
      Copyright() {
        let str         = '';
        let MainContact = Object.keys(this.WebSetting).indexOf('MAIN_CONTACT') >= 0 ? this.WebSetting.MAIN_CONTACT : '';
        switch (MainContact) {
          case 'WHATSAPP':
            if (Object.keys(this.WebSetting).indexOf('WHATSAPPID') && this.WebSetting.WHATSAPPID)
              str = str + `Whats App ID : ${this.WebSetting.WHATSAPPID} / `;
            break;
          default:
            if (Object.keys(this.WebSetting).indexOf('LINEID') && this.WebSetting.LINEID)
              str = str + `LINE ID : ${this.WebSetting.LINEID} / `;
        }

        return str + `Copyright © ${this.WebSetting.WEBNAME} Co. Ltd. All rights reserved.`
      },
      DEF_webStyle(){
        return process.env .DEF_webStyle || 'default';
      },
      idxFooterList () {
        return this.idxFooter.filter((Item) => {
          return Item.url != '/download/APP';
        }).map((Item) => {
          return Item;
        });
      },
      ...mapState(['WebSetting', 'IsAudio', 'IsLoading']),
      ...mapGetters('webSetting', ['FestivalClass']),
      ...mapGetters(["FooterProductLogo"]),
    }
  };
</script>
