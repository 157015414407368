<template>
  <header>
    <div class="nav-header" :class="$auth.$state.loggedIn? 'after':''"><!-- 登入前: ,  登入後:after-->
      <nuxt-link to="/" class="logo"></nuxt-link>
      <div class="nav_layout">
        <ul class="navbar">
          <modNavLink v-for="(item, index) in base" v-model="base[index]" :key="index" v-if="item.type" />
        </ul>
        <template v-if="DEF_webStyle === 'kb88'">
          <audioPlay />
        </template>
      </div>

      <!-- <idxMarquee04 /> -->
      <modNavRtB v-if="$auth.$state.loggedIn !== true" />
      <modNavRtA v-if="$auth.$state.loggedIn === true" />
      <modNavCtrA v-if="$auth.$state.loggedIn === true" />

    </div>
    <div class="bgDecBox">
      <div class="decItem"></div>
    </div>
  </header>
</template>

<script>
  const vuei18n = require(`~/components/${process.env.DEF_language}.vue`).default;
  const audioPlay = require(`~/components/${process.env.DEF_audioplay}.vue`).default;
  import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
  import modNavLink from "~/components/desktop/header/modNavLink.vue";
  import modNavRtA from "~/components/desktop/header/modNavRtA.vue";
  import modNavCtrA from "~/components/desktop/header/modNavCtrA.vue";
  import modNavCtrB from "~/components/desktop/header/modNavCtrB.vue";
  import modNavRtB from "~/components/desktop/header/modNavRtB.vue";
  // import idxMarquee04 from "~/components/desktop/idxMarquee.vue";
  export default {
    components: {
      modNavLink,
      modNavCtrA,
      modNavCtrB,
      modNavRtA,
      modNavRtB,
      vuei18n,
      audioPlay
      //idxMarquee04
    },
    data(){
      return{
        base: []
      }
    },
    mounted() {
      this.init();
      this.changeLang();
    },
    methods: {
      init() {
        if (this.ErrorMsg != "") {
          this._showMsgBox({ title: "", msg: this.ErrorMsg }).then((res) => {
            this._setErrorMsg("");
          });
        }
      },
      baseData(){
        return this.Meun.map(function(value){
          return{
            type: 'a',
            blank: value.blank,
            class: value.class,
            subtxt: value.subtxt,
            title: value.title,
            url: value.url
          }
        })
      },
      changeLang(){
        this.base = this.baseData();
      },
      ...mapActions(["_showMsgBox"]),
      ...mapMutations(["_setErrorMsg"]),
    },
    computed: {
      DEF_webStyle() {
        return process.env.DEF_webStyle || 'default';
      },
      ...mapState(['ErrorMsg', 'WebSetting']),
      ...mapGetters(["Meun"]),
    },
    watch: {
      'ErrorMsg' (ErrorMsg) {
        if (ErrorMsg && ErrorMsg != '') {
          this._showMsgBox({ title: "", msg: ErrorMsg }).then((res) => {
            this._setErrorMsg("");
          });
        }
      }
    }
  }
</script>
